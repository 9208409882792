import React, { useState, useEffect } from 'react';
import io from 'socket.io-client';
import '../Styles/Overview.scss';

const socket = io('https://apidashboard-vuou.onrender.com', {
    transports: ['websocket', 'polling']  // Essaie d'utiliser les deux types de transport.
  });
  

const Overview = () => {
    const [data, setData] = useState([]);
    const [previousData, setPreviousData] = useState([]);

    useEffect(() => {
        socket.emit('request_data_overview');
        

        socket.on('update_data', (newData) => {
            // Save the previous data state to calculate changes
            setPreviousData(data);

            // Update the current data state
            setData([
                { title: 'Vidéos Totales', value: newData.video_count },
                { title: 'Chaînes Analysées', value: newData.channel_count },
                { title: 'Mots-Clés Traités', value: newData.keyword_count },
            ]);
        });



        // Cleanup at the end
        return () => {
            socket.off('update_data');
        };
    }, [data]); // Dependency on data to keep track of changes

    // Function to calculate percentage change
    const calculateChange = (current, previous) => {
        if (previous === undefined) return null; // No previous data yet
        const change = ((current - previous) / Math.abs(previous)) * 100;
        return change.toFixed(2); // Rounded to two decimal places
    };

    return (
        <section className="overview">
            {data.map((item, index) => {
                // Get the previous value corresponding to the current item
                const previousValue = previousData[index]?.value;
                const change = calculateChange(item.value, previousValue);

                return (
                    <div className="card" key={index}>
                        <div className="card__header">
                            <h3>{item.title}</h3>
                            {change !== null && (
                                <span className={`change ${change >= 0 ? 'positive' : 'negative'}`}>
                                    {change >= 0 ? '▲' : '▼'} {Math.abs(change)}%
                                </span>
                            )}
                        </div>
                        <p className="card__value">{item.value}</p>
                    </div>
                );
            })}
        </section>
    );
};

export default Overview;
