import React from 'react';
import Sidebar from '../Components/SideBar.jsx';
import Header from '../Components/Header.jsx';
import Overview from '../Components/Overview.jsx';
// import PopularTopics from '../Components/PopularTopics.jsx';
// import RelevanceAnalysis from '../Components/RelevanceAnalysis.jsx';
// import RegionalTrends from '../Components/RegionalTrend.jsx';
// import KeywordFrequency from '../Components/KeyWordFrequency.jsx';
import GeneratedReports from '../Components/GeneratedReports.jsx';
// import PieChartComponent from '../Components/BartChart.jsx';

const Dashboard = () => {
  return (
    <div className="dashboard">
      <Sidebar />
      <div className="dashboard__main-content">
        <Header />
        <div className="dashboard__content">
          <Overview />
          {/* <PopularTopics />
          <RelevanceAnalysis />
          <RegionalTrends />
          <KeywordFrequency />
          <PieChartComponent /> */}
          <GeneratedReports />
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
