import React from 'react';
import { FaSearch, FaBars,FaBell, FaEnvelope, FaCheckCircle } from 'react-icons/fa';
import '../Styles/Header.scss';


const Header = () => {
  return (
    <div className="header">
      <div className="header__left">
        <FaBars className="header__menu-icon" />
        <h2 className="header__title">Dashboard</h2>
      </div>
      <div className="header__search">
        <input type="text" placeholder="Search here..." />
        <FaSearch className="header__search-icon" />
      </div>
      <div className="header__right">
        <FaBell className="header__icon" />
        <FaEnvelope className="header__icon" />
        <FaCheckCircle className="header__icon" />
        <div className="header__profile">
          <img src="path/to/profile-pic.jpg" alt="Profile" className="header__profile-pic" />
          <div className="header__profile-info">
            <span className="header__name">Franklin Jr.</span>
            <span className="header__email">admin@company.com</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
