import React from 'react';
import { FaHome, FaChartBar, FaVideo, FaUserFriends, FaFileAlt } from 'react-icons/fa';
import '../Styles/sidebar.scss';

const Sidebar = () => {
  return (
    <div className="sidebar">
      <div className="sidebar__header">
        <h2>Dashboard</h2>
      </div>
      <ul className="sidebar__menu">
        <li className="sidebar__item">
          <FaHome /> <span>Overview</span>
        </li>
        <li className="sidebar__item">
          <FaChartBar /> <span>Relevance Analysis</span>
        </li>
        <li className="sidebar__item">
          <FaVideo /> <span>Videos</span>
        </li>
        <li className="sidebar__item">
          <FaUserFriends /> <span>Channels</span>
        </li>
        <li className="sidebar__item">
          <FaFileAlt /> <span>Reports</span>
        </li>
      </ul>
    </div>
  );
};

export default Sidebar;
