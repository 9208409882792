import React from 'react';
// import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import Dashboard from './Pages/Dashboard.jsx';

const App = () => {
  return (
    <>
      <Dashboard />
    </>
)};

export default App;
