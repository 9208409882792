import React from 'react';
import '../Styles/GeneratedReport.scss';

const GeneratedReports = () => {
  const reports = [
    { name: 'Rapport Janvier 2024', status: 'Terminé' },
    { name: 'Rapport Février 2024', status: 'En cours' },
    { name: 'Rapport Mars 2024', status: 'En attente' },
  ];

  return (
    <section className="generated-reports">
      <h3>Rapports Prêts à Télécharger</h3>
      <table className="styled-table">
        <thead>
          <tr>
            <th>Nom du Rapport</th>
            <th>Statut</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {reports.map((report, index) => (
            <tr key={index} className={`status-${report.status.toLowerCase().replace(' ', '-')}`}>
              <td>{report.name}</td>
              <td>
                <span className={`status-badge ${report.status.toLowerCase()}`}>{report.status}</span>
              </td>
              <td>
                {report.status === 'Terminé' ? (
                  <button className="download-button">Télécharger</button>
                ) : (
                  <button className="disabled-button" disabled>Indisponible</button>
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </section>
  );
};

export default GeneratedReports;
